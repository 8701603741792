
import * as Components from "./Thaipepper"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "thaipepper"
}

